var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"without-wrapper",attrs:{"visible":_vm.dialogVisible,"before-close":_vm.dialogClose,"fullscreen":true,"append-to-body":true,"width":"80%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.title)?_c('span',{class:[
      { 'screensaver-help' : _vm.type === 'manual' }
    ],attrs:{"slot":"title"},domProps:{"innerHTML":_vm._s(_vm.title)},slot:"title"}):_vm._e(),(_vm.headline)?_c('h2',{class:[
      { 'screensaver-help' : _vm.type === 'manual' }
    ],domProps:{"innerHTML":_vm._s(_vm.headline)}}):_vm._e(),(_vm.description)?_c('div',{class:[
      'description',
      { 'screensaver-help' : _vm.type === 'manual' }
    ],domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),(!_vm.description && _vm.type === 'manual')?_c('div',{staticClass:"description description-static"},[_c('div',{staticClass:"steps"},[_c('div',{staticClass:"step"},[_c('div',{staticClass:"text"},[_c('p',{domProps:{"innerHTML":_vm._s(("\n            <span class='number'>1</span>\n            " + (_vm.$t('terminal.index.dialog.manual.step.third.headline')) + "\n          "))}})]),_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/images/index-manual-step-3@3x.png"),"alt":_vm.$t('terminal.index.dialog.manual.step.third.headline')}})])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"text"},[_c('p',{domProps:{"innerHTML":_vm._s(("\n            <span class='number'>2</span>\n            " + (_vm.$t('terminal.index.dialog.manual.step.fourth.headline')) + "\n          "))}})]),_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/images/index-manual-step-4@3x.png"),"alt":_vm.$t('terminal.index.dialog.manual.step.fourth.headline')}})])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"text"},[_c('p',{domProps:{"innerHTML":_vm._s(("\n            <span class='number'>3</span>\n            " + (_vm.$t('terminal.index.dialog.manual.step.fifth.headline')) + "\n          "))}})]),_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/images/index-manual-step-yellow-5@3x.png"),"alt":_vm.$t('terminal.index.dialog.manual.step.fifth.headline')}})])])]),(_vm.type === 'manual')?_c('div',{staticClass:"close-screensaver-help",on:{"click":_vm.dialogClose}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }